import React, { useEffect, useLayoutEffect, useState } from 'react';
import '../../assets/css/tournament.css';
import Loader from "../includes/Loader";
import VideoModal from "../modals/VideoModal";
import { getYoutubeVideos } from '../api_calls/getYoutubeVideos';


function YouTubeVideosPage() {
    const [loading, setLoading] = useState(true);

    const [videosList, setVideosList] = useState([]);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [youtubeVideoUrl, setYoutubeVideoUrl] = useState(null);

    useLayoutEffect(() => {
        document.title = 'Tournament Highlights | Jazz Esl';
    }, [])
    useEffect(() => {
        async function getChannelVideos() {
            try {
                const response = await getYoutubeVideos();
                const videoLinks = response.data.data.map(data => ({
                    id: data.id.videoId,
                    title: data.title,
                    thumbnail: data.thumbnail,
                    link: data.link,
                }));


                setVideosList(videoLinks);
            } catch (error) {
                setVideosList([]);
                console.error('Error fetching channel videos:', error);
            } finally {
                setLoading(false);
            }
        }

        getChannelVideos();
    }, []);

    function openVideoModal(videoUrl) {
        setYoutubeVideoUrl(videoUrl);
        setIsVideoModalOpen(true);
    }

    return (
        <section>
            <div className="cards-wrapper-tournament cards-wrapper-tournament1 col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Tournament Highlights</h2>
                    <p className="tournament-sub-heading">Watch the players battle to take the crown</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        videosList.length > 0 && videosList.map((video, index) => (
                            <div key={`${video.id}-${index}`} onClick={() => openVideoModal(video.link)} className="col-12 col-sm-12 col-md-4 col-lg-4">
                                {/* <NavLink to={`/game-challenges/${gameTask.gameId}`}> */}
                                <div className="cards-tournament-round">
                                    <img src={video.thumbnail} alt="tournament-img" />
                                    <div className="tournament-card-info">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="tournament-info-heading">{video.title}</h6>
                                                {/* <p className="card-heading-tournament">Win Credits</p> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* </NavLink> */}
                            </div>
                        ))
                    )}


                    {videosList.length <= 0 && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No video found</p>
                        </div>
                    )}

                    {isVideoModalOpen && (
                        <VideoModal videoUrl={youtubeVideoUrl} onClose={() => setIsVideoModalOpen(false)} />
                    )}

                </div>
            </div>
        </section>
    );
}

export default YouTubeVideosPage;