import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/profile.css';
import profileImg from '../../assets/images/profile/profile-img.png';
import edit from '../../assets/images/icons/edit-icon.png';
import cameraImg from '../../assets/images/icons/camera.svg';
import tick from '../../assets/images/icons/Tick Square.svg';
import { Link, useParams } from "react-router-dom";
import arrowLeft from "../../assets/images/icons/Arrow-Left.png";
import logo from "../../assets/images/logo.png";
import { requestGetHTTP, requestPostHTTP } from "../helpers/helper";
import Loader from "../includes/Loader";
import rewardsImg from "../../assets/images/icons/rewards.png";

function Profile() {
    const [profile, setProfile] = useState([]);
    const [profileEmail, setProfileEmail] = useState([]);
    const [notProfile, setNotProfile] = useState(false);
    const [loading, setLoading] = useState(true);
    const [profileName, setProfileName] = useState('');
    const [bioDesc, setBioDesc] = useState(null);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [imageSrcMsgSuccess, setImageSrcMsgSuccess] = useState('');
    const [imageSrcTemp, setImageSrcTemp] = useState('');
    const [imageSrcMsg, setImageSrcMsg] = useState(false);
    const profileImgInputRef = useRef(null);
    const profileNameRef = useRef(null);
    const profileEmailRef = useRef(null);
    const bioTextRef = useRef(null);
    const { editable } = useParams();
    const showSuccessModal = useRef(null);
    const [isValidEmail, setIsValidEmail] = useState(true);

    async function fetchProfileData() {
        try {
            let getProfile = await requestGetHTTP('/v1/user/profile');

            if (getProfile) {
                localStorage.setItem('profileData', JSON.stringify(getProfile));
                setProfile(getProfile);
                setProfileName(getProfile.username);
                setProfileEmail(getProfile.email);
                setImageSrc(getProfile.profileImage);
                setImageSrcTemp(getProfile.profileImage);

            } else {
                setNotProfile(true);
            }
        } catch (error) {
            console.error('profile data fetch', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (editable === 'yes') {
            setIsEditProfile(true);
        }
        fetchProfileData();
    }, []);

    /**
     * Update Profile
     */
    function postUpdateProfile() {
        if (isValidEmail === false) {
            return false;
        }
        try {
            const responseSubmitEntry = requestPostHTTP('/v1/user/profile/complete', {
                image: imageSrc,
                firstname: profile.first_name,
                lastname: profile.last_name,
                username: profileName,
                email: profileEmail,
                gender: profile.gender,
                bio: bioDesc,
            });

            if (responseSubmitEntry) {
                localStorage.removeItem("profileData");
                setImageSrcMsgSuccess('Profile Updated Successfully');
                fetchProfileData();
                showSuccessModal.current.click();
            } else {
                setImageSrcMsg('Please try again');
            }
        } catch (error) {
            console.error('Profile update request ', error);
        } finally {
            setLoading(false);
        }

    };

    /**
     * Bio text
     */
    const handleBioTextChange = () => {
        if (bioTextRef.current) {
            const bioTextArea = bioTextRef.current.value;
            setBioDesc(bioTextArea);
        }
    };

    /**
     * hove camera click manage
     */
    const handleUploadClick = () => {
        profileImgInputRef.current.click();
    };

    /**
     *
     * @param e
     * Onchange profile image view
     */
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size;
            reader.onloadend = () => {
                if (fsize <= 5024000) {
                    if (profileImgInputRef.current) {
                        // const file = profileImgInputRef.current.files[0];
                        setImageSrc(reader.result);
                        setImageSrcMsg(false);
                    }
                    setImageSrcTemp(reader.result);
                } else {
                    setImageSrc(null);
                    setImageSrcMsg('Image should be less then 5mb');
                }
            };
            reader.readAsDataURL(file);
        }
    };

    /**
     * Profile Email change
     */
    const handleChangeProfileEmail = () => {
        if (profileEmailRef.current) {
            const profileEmail = profileEmailRef.current.value;

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(profileEmail);
            setIsValidEmail(isValid);
            setProfileEmail(profileEmail);
        }
    };

    /**
     * Profile Name change
     */
    const handleChangeProfileName = () => {
        if (profileNameRef.current) {
            const profileName = profileNameRef.current.value;
            setProfileName(profileName);
        }
    };

    return (
        <>
            <section>
                <div className="header mobile-header profile-header">
                    <nav className="navbar navbar-expand-sm navbar-dark">
                        <Link to="/">
                            <img className="arrow-back" src={arrowLeft} />
                            <span className="mob-notification-heading">Profile</span>
                        </Link>
                        <span>
                            <Link to="/">
                                <img className="mobile-header-logo" src={logo} alt="logo"
                                    className="header-logo" /></Link>
                        </span>
                    </nav>
                </div>
            </section>

            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="cards-wrapper-profile col-12 col-sm-12 col-md-12 col-lg-12">
                        <h2 className="profile-headingz mb-4">Profile</h2>
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-5 col-lg-4">
                                {imageSrcMsg && <p className="chooseProfile-file-text text-red">{imageSrcMsg}</p>}
                                <div className="profile-content">
                                    <div className='background-profile'>

                                        <img className="user-profile-image"
                                            src={imageSrcTemp ? imageSrcTemp : profileImg}
                                            alt="profile-img"
                                            width="50px" />

                                        {isEditProfile && (<div className="camera-overlay">
                                            <div className="camera-icon" onClick={handleUploadClick}>
                                                <img src={cameraImg} alt="Upload" />
                                                <input type="file"
                                                    ref={profileImgInputRef}
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                    id="profile-upload"
                                                    accept="image/png, image/jpeg, image/jpg" name="profile_upload"
                                                    className="profile-image-upload" />
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="text">
                                        <p className="profile-username">
                                            {isEditProfile ?
                                                (<input
                                                    type="text"
                                                    value={profileName}
                                                    placeholder="Enter Name"
                                                    className="profile-username-input"
                                                    ref={profileNameRef}
                                                    onChange={handleChangeProfileName}
                                                />)
                                                : (
                                                    profile.username
                                                )
                                            }
                                        </p>
                                        <span className="profile-userid">User ID: {profile.phone}</span>
                                    </div>
                                    {isEditProfile ?
                                        (
                                            <div className="profile-edit" onClick={() => isValidEmail ? setIsEditProfile(false) : setIsEditProfile(true)}>
                                                <img className="profile-tick" src={tick} alt=""
                                                    onClick={postUpdateProfile} />
                                            </div>) : (
                                            <div className="profile-edit" onClick={() => setIsEditProfile(true)}>
                                                <img src={edit} alt="" />
                                            </div>)}

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <div className="user-stats">
                                    <div className="row">

                                        <div className="col-6 col-sm-6 col-md-3 col-lg-3 pb-5">
                                            <h3 className="user-score">{profile.total_tournament}</h3>
                                            <h4 className="user-score-detail">Tournaments Played</h4>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                            <h3 className="user-score">{profile.completed_tasks_total}</h3>
                                            <h4 className="user-score-detail">Completed Tasks</h4>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                            <h3 className="user-score">{profile.credits}</h3>
                                            <h4 className="user-score-detail">Credits Earned</h4>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                            <h3 className="user-score">{profile.items_redeemed}</h3>
                                            <h4 className="user-score-detail">Redeemed Items</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cards-wrapper-profile col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="row user-bio align-items-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h2 className="bio m-0">Email </h2>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {isEditProfile ?
                                    (
                                        <input
                                            type="text"
                                            value={profileEmail}
                                            placeholder="Enter Email"
                                            className="bio-detail-textarea"
                                            onChange={handleChangeProfileEmail}
                                            ref={profileEmailRef}
                                        />
                                    ) : (
                                        <div className="user-stats">
                                            <p className="bio-detail">
                                                <span>{profileEmail}</span>
                                            </p>
                                        </div>
                                    )}
                            </div>
                            {!isValidEmail && (<p className="text-red mt-1">Please enter valid email address</p>)}
                        </div>

                        <div className="row user-bio align-items-center">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <h2 className="bio m-0">Bio </h2>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="bio-edit">
                                    <img src="" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {isEditProfile ?
                                    (
                                        <textarea ref={bioTextRef} onChange={handleBioTextChange} name="user_desc"
                                            className="bio-detail-textarea">{profile.bio}</textarea>
                                    ) : (
                                        <div className="user-stats">
                                            <p className="bio-detail">
                                                <span>{profile.bio}</span>
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {notProfile && (
                <div className="d-flex justify-content-center align-items-center no-data-found">
                    <p>Please refresh again....</p>
                </div>
            )}
            {/*Model*/}
            <div className="modal fade subscribe-modal" id="showSuccess" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                            <p className="subscribe-heading-modal">{imageSrcMsgSuccess}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={showSuccessModal} data-bs-toggle="modal" data-bs-target="#showSuccess">modal box</button>
        </>
    );
}

export default Profile;
