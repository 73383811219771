import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import '../../assets/css/card-slider.css';
import arrowImg from '../../assets/images/icons/errow.png';
// import Slider from 'react-slick';
import h5GamesJsonData from "../data/h-five-games.json";
import Loader from "./Loader";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const HFiveGameSlider = (props) => {
    const [h5Games, setH5Games] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setH5Games(Object.values(h5GamesJsonData));
        setLoading(false);
    }, []);



    return (
        <section className='section-card'>
            <div className="mt-4 cards-wrapper card-slider-wrapper play-game-slider" style={{ paddingLeft: "5px" }}>
                <div className="card-slider-heading">
                    <h2 className="headingz mb-4">Play
                        <span className="errow-icon">
                            <img src={arrowImg} alt="errow-img" />
                        </span>
                    </h2>
                    <p className="main-slider-heading">Instant Game Plays</p>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                        loop={true}
                        // centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            270: { // when window width is >= 320px (mobile devices)
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1.3,
                            },
                            331: {
                                slidesPerView: 1.4,
                            },
                            345: {
                                slidesPerView: 1.45,
                            },
                            360: {
                                slidesPerView: 1.5,
                            },
                            370: {
                                slidesPerView: 1.55,
                            },
                            380: {
                                slidesPerView: 1.6,
                            },
                            401: {
                                slidesPerView: 1.65,
                            },
                            420: {
                                slidesPerView: 1.7,
                            },
                            440: {
                                slidesPerView: 1.8,
                            },
                            465: {
                                slidesPerView: 1.9,
                            },
                            489: {
                                slidesPerView: 1.99,
                            },
                            511: {
                                slidesPerView: 2.1,
                            },
                            560: {
                                slidesPerView: 2.3,
                            },
                            590: {
                                slidesPerView: 2.4,
                            },
                            621: {
                                slidesPerView: 2.5,
                            },
                            640: {
                                slidesPerView: 2.6,
                            },
                            701: {
                                slidesPerView: 2.9,
                            },
                            777: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.2,
                            },
                            870: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.5,
                            },
                            941: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.7,
                            },
                            1024: { // when window width is >= 1024px (desktops)
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Autoplay]}

                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {h5Games.length > 0 && h5Games.map((h5Game, index) => (
                            <SwiperSlide style={{ marginLeft: "15px" }} key={index}>
                                <NavLink to={`/play-games/${index}`}>
                                    <div className="cards-round">
                                        <img className="card-img" src={window.location.origin + h5Game.thumbnail}
                                            alt="h5-img" />
                                        <div className="cards-info">
                                            <h3 className="cards-label text-white">
                                                {h5Game.name}
                                            </h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}

                {h5Games.length < 1 && (
                    <div className="d-flex justify-content-center align-items-center no-data-found">
                        <p>No H5 games found</p>
                    </div>
                )}

            </div>
        </section>
    );
}

export default HFiveGameSlider;
