import React, {useEffect, useState} from 'react';
import '../../assets/css/mobile-notification.css';
import deleteImg from "../../assets/images/icons/Delete.png";
import arrowLeft from "../../assets/images/icons/Arrow-Left.png";
import logo from '../../assets/images/logo.png';
import {Link} from "react-router-dom";
import {requestPostHTTP} from "../helpers/helper";
import {isLogin} from "../auth/AuthController";

function MobileNotification() {
    const [notifications, setNotifications] = useState([]);
    const [noNotifications, setNoNotifications] = useState(false);

    useEffect(() => {
        async function fetchReqData() {
            try {
                const getNotifications = await requestPostHTTP('/v1/user/notifications');
                if (getNotifications.notifications.length > 0) {
                    let finalNotifications = getNotifications.notifications.filter(notification => notification.seen === 0);
                    if(finalNotifications.length > 0){
                        setNotifications(finalNotifications);
                    }else{
                        setNoNotifications(true);
                    }
                } else {
                    setNoNotifications(true);
                }
            } catch (error) {
                console.error('Fetch Notifications ', error);
            }
        }
        if(isLogin()){
            fetchReqData();
        }

    }, []);
    return (
        <>
            <section>
                <div className="header mobile-header">
                    <nav className="navbar navbar-expand-sm navbar-dark">
                        <Link to="/">
                            <img className="arrow-back" src={arrowLeft}/>
                            <span className="mob-notification-heading">Notifications</span>
                        </Link>
                        <span>
                                <Link to="/">    <img className="mobile-header-logo" src={logo} alt="logo" className="header-logo"/></Link>
                            </span>
                    </nav>
                </div>
            </section>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                    <div className="mobile-notification-page">
                        {/* notification.url*/}
                        {notifications.length > 0 && notifications.map((notification , key) => (
                            <div key={key} className="mobile-page-notification-text">{notification.message}
                                <img src={deleteImg} className="notification-img" alt="cross-icon"/>
                            </div>
                        ))}
                        {noNotifications && (
                            <div className="mobile-page-notification-text">No new notifications</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNotification;
