import React, { useState, useEffect } from 'react';
import '../../assets/css/card-slider.css';
import arrowImg from '../../assets/images/icons/errow.png';
// import Slider from 'react-slick';
import { requestGetHTTP } from "../helpers/helper";
import Loader from "./Loader";
import { useSelector } from 'react-redux';
import { isLogin } from "../auth/AuthController";
import { NavLink } from "react-router-dom";
// import rewardsImg from "../../assets/images/icons/rewards.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const TournmentSlider = () => {
    const [weeklyTournament, setWeeklyTournament] = useState([]);
    const [notFoundTournament, setNotFoundTournament] = useState(false);
    const [loading, setLoading] = useState(true);
    const modal = useSelector((state) => state.openSubscribedModal);
    // const [tournamentCheckIn, setTournamentCheckIn] = useState([]);
    // const showSuccessModal = useRef(null);

    useEffect(() => {
        async function fetchTournamentData() {
            try {
                const getWeeklyTournament = await requestGetHTTP('/v1/tournaments/all');
                if (getWeeklyTournament.data.length > 0) {
                    setWeeklyTournament(getWeeklyTournament.data);
                } else {
                    setNotFoundTournament(true);
                }
            } catch (error) {
                console.error('Error fetching tournament data:', error);
                setNotFoundTournament(true);
            } finally {
                setLoading(false);
            }
        }

        fetchTournamentData();
    }, []);


    /**
     *
     * @param event
     */
    const checkLogin = (event) => {
        if (!isLogin()) {
            event.preventDefault();
            modal.subscribedModalRef.current.click();
        }
    }

    const windowWidth = window.innerWidth;
    let slidesToShow = 3.999;
    if (windowWidth > 850 && windowWidth <= 900) {
        slidesToShow = 2.999;
    } else if (windowWidth <= 850) {
        slidesToShow = 1;
    }
    const settings = {
        dots: false,
        infinite: weeklyTournament.length > 4,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
    };

    return (
        <section className='section-card-tournament'>
            <div className="mt-4 cards-wrapper card-slider-wrapper" style={{ paddingLeft: "5px" }}>
                <div className="card-slider-heading">
                    <h2 className="headingz mb-4">Weekly Tournaments
                        <span className="errow-icon">
                            <img src={arrowImg} alt="errow-img" />
                        </span>
                    </h2>
                    <p className="main-slider-heading">Join tournaments to WIN BIG</p>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                        loop={true}
                        // centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            270: { // when window width is >= 320px (mobile devices)
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1.3,
                            },
                            331: {
                                slidesPerView: 1.4,
                            },
                            345: {
                                slidesPerView: 1.45,
                            },
                            360: {
                                slidesPerView: 1.5,
                            },
                            370: {
                                slidesPerView: 1.55,
                            },
                            380: {
                                slidesPerView: 1.6,
                            },
                            401: {
                                slidesPerView: 1.65,
                            },
                            420: {
                                slidesPerView: 1.7,
                            },
                            440: {
                                slidesPerView: 1.8,
                            },
                            465: {
                                slidesPerView: 1.9,
                            },
                            489: {
                                slidesPerView: 1.99,
                            },
                            511: {
                                slidesPerView: 2.1,
                            },
                            560: {
                                slidesPerView: 2.3,
                            },
                            590: {
                                slidesPerView: 2.4,
                            },
                            621: {
                                slidesPerView: 2.5,
                            },
                            640: {
                                slidesPerView: 2.6,
                            },
                            701: {
                                slidesPerView: 2.9,
                            },
                            777: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.2,
                            },
                            870: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.5,
                            },
                            941: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.7,
                            },
                            1024: { // when window width is >= 1024px (desktops)
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Autoplay]}

                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {weeklyTournament.map(cardSlider => (
                            <SwiperSlide className="d-flex justify-content-center" style={{ marginLeft: "15px", background: "#1F222A", padding: "15px 0 15px 0", borderRadius: "25px", minWidth: "295px" }} key={cardSlider.id}>
                                <div className="cards-round w275i" style={{ background: "transparent" }}>
                                    <NavLink onClick={(event) => checkLogin(event)} to={`/tournament-details/${cardSlider.tourn_id}`}>
                                        <img src={cardSlider.thumbnail} alt="tournament-img" style={{ width: "100%" }} />
                                        <div className="col-8 cards-info float-start">
                                            <h3 className="cards-label text-white">
                                                {cardSlider.tourn_name}
                                            </h3>
                                            <p className="card-heading-tournament fs-15"> Prizes</p>
                                            <p className="card-heading-tournament fs-15">1st: {cardSlider.prize} {cardSlider.tourn_type}</p>
                                            {cardSlider.second_prize && (
                                                <p className="card-heading-tournament fs-15">
                                                    2nd: {cardSlider.second_prize} {cardSlider.tourn_type}
                                                </p>
                                            )}
                                            {cardSlider.third_prize && (
                                                <p className="card-heading-tournament fs-15">3rd: {cardSlider.third_prize} {cardSlider.tourn_type}</p>
                                            )}

                                            <p className="mt-3 m-0 pb-2 tournament-total-participant">Participant: {cardSlider.total_checkin}</p>
                                        </div>
                                        <div className="col-4 comming-soon-slider-tournament float-end d-flex flex-column">
                                            <p>Starting at</p>
                                            <p style={{ minWidth: "73px" }}>{cardSlider.time.split(" ")[0]}</p>
                                            <p>{cardSlider.time.split(" ")[1]}</p>
                                            <div style={{ width: "150px", right: "40px", position: "relative", alignSelf: "flex-start", marginTop: "auto", marginBottom: "25px" }}>
                                                {cardSlider.isEntryFree === 0 || !cardSlider.isEntryFree ?
                                                    <p style={{ fontSize: "12px", color: "white", lineHeight: "20px", textAlign: "center" }}>Enter from FREE!</p>
                                                    : <p style={{ fontSize: "12px", color: "white", lineHeight: "20px", textAlign: "center" }}> <span style={{ fontSize: "13px" }}>Tournament</span> <br /> Entry Fee-{cardSlider.entryFee} Credits</p>}

                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}

                {notFoundTournament && (
                    <div className="d-flex justify-content-center align-items-center no-data-found">
                        <p>No tournaments found</p>
                    </div>
                )}

            </div>
        </section>
    );
}

export default TournmentSlider;
