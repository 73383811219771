import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {requestPostHTTP} from "../helpers/helper";
import axios from 'axios';
import rewardsImg from "../../assets/images/icons/rewards.png";
import Loader from "../includes/Loader";

function DiscordAuthCallback() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const showSuccessModal = useRef(null);


    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const code = query.get('code');

        if (code) {
            // Exchange code for access token
            axios.post('https://discord.com/api/oauth2/token', {
                client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
                client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
                grant_type: 'authorization_code',
                code,
                redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
            },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(response => {
                const { access_token } = response.data;
                // Use the access token to get user data and manage authentication state
                axios.get('https://discord.com/api/users/@me', {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }).then(userResponse => {
                    const userData = userResponse.data;
                    sendDiscordUserInfo(userData);
                    setLoading(false);
                    showSuccessModal.current.click();
                    console.log('User data:', userData);
                });
            });
        }

        /**
         * sendDiscordUserInfo
         */
        function sendDiscordUserInfo(userData){
            requestPostHTTP('/v1/send_discord_user_info', {
                'discord_user_id': userData.id,
                'discord_user_name': userData.username,
                'discord_user_global_name': userData.global_name,
                'discord_user_email': userData.email
            });
            return true;
        }

    }, [location]);

    return (
        <div>
            {loading ? ( <Loader/> ) : ''}
            {/*Model*/}
            <div className="modal fade subscribe-modal" id="showSuccess" tabIndex="-1"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                            <p className="subscribe-heading-modal">Join our group for an enhanced experience! Check your direct messages for the invite link to the JazzESL Discord server. Thank you!</p>
                            <Link className="subscribe-btn-modal" to="/">
                                Okay
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{display: 'none'}} ref={showSuccessModal} data-bs-toggle="modal" data-bs-target="#showSuccess">modal box</button>
        </div>
    );
}

export default DiscordAuthCallback;
