import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import '../../assets/css/card-slider.css';
import arrowImg from '../../assets/images/icons/errow.png';
import Loader from "./Loader";
import { requestPostHTTP } from "../helpers/helper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
const GameTaskSlider = (props) => {
    const [gameTasks, setGameTasks] = useState([]);
    const [notFoundTasks, setNotFoundTasks] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function fetchGameTaskData() {
            try {
                const getDailyGameTasks = await requestPostHTTP('/v1/challenges/games');
                if (getDailyGameTasks.data.length > 0) {
                    setGameTasks(getDailyGameTasks.data);
                } else {
                    setNotFoundTasks(true);
                }
            } catch (error) {
                console.error('Error fetching game task data:', error);
                setNotFoundTasks(true);
            } finally {
                setLoading(false);
            }
        }

        fetchGameTaskData();

    }, []);



    return (
        <section className='section-card'>
            <div className="mt-4 cards-wrapper card-slider-wrapper gaming-tasks-slider-wrapper" style={{ paddingLeft: "5px" }}>
                <div className="card-slider-heading">
                    <h2 className="headingz mb-4">Daily Gaming Tasks
                        <span className="errow-icon">
                            <img src={arrowImg} alt="errow-img" />
                        </span>
                    </h2>
                    <p className="main-slider-heading">Choose your favorite game and play the daily gaming tasks</p>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                        loop={true}
                        // centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            270: { // when window width is >= 320px (mobile devices)
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1.3,
                            },
                            331: {
                                slidesPerView: 1.4,
                            },
                            345: {
                                slidesPerView: 1.45,
                            },
                            360: {
                                slidesPerView: 1.5,
                            },
                            370: {
                                slidesPerView: 1.55,
                            },
                            380: {
                                slidesPerView: 1.6,
                            },
                            401: {
                                slidesPerView: 1.65,
                            },
                            420: {
                                slidesPerView: 1.7,
                            },
                            440: {
                                slidesPerView: 1.8,
                            },
                            465: {
                                slidesPerView: 1.9,
                            },
                            489: {
                                slidesPerView: 1.99,
                            },
                            511: {
                                slidesPerView: 2.1,
                            },
                            560: {
                                slidesPerView: 2.3,
                            },
                            590: {
                                slidesPerView: 2.4,
                            },
                            621: {
                                slidesPerView: 2.5,
                            },
                            640: {
                                slidesPerView: 2.6,
                            },
                            701: {
                                slidesPerView: 2.9,
                            },
                            777: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.2,
                            },
                            870: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.5,
                            },
                            941: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.7,
                            },
                            1024: { // when window width is >= 1024px (desktops)
                                slidesPerView: 4,
                            },
                        }}
                    // modules={[Autoplay]}

                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {gameTasks.length > 0 && gameTasks.map(cardSlider => (
                            <SwiperSlide style={{ marginLeft: "15px", maxWidth: "275px" }} key={cardSlider.id}>
                                <NavLink to={`/game-challenges/${cardSlider.gameId}`}>
                                    <div className="cards-round slide-card-responsive width-c300">
                                        <img className="card-img mh-300" src={cardSlider.thumbnail} alt="Card-img" />
                                        <div className="cards-info">
                                            <h3 className="cards-label text-white fs-18">
                                                {cardSlider.game}
                                            </h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                {notFoundTasks && (
                    <div className="d-flex justify-content-center align-items-center no-data-found">
                        <p>No daily gaming tasks found</p>
                    </div>
                )}

            </div>
        </section >
    );
}

export default GameTaskSlider;
