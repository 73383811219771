import React, { useState, useEffect, useLayoutEffect } from 'react';
import '../../assets/css/leaderboard.css';
import firstSvg from '../../assets/images/leaderboard/1.svg';
import secondSvg from '../../assets/images/leaderboard/2.svg';
import thirdSvg from '../../assets/images/leaderboard/3.svg';
import { getLeaderboard } from '../api_calls/getLeaderboard';
import Loader from '../includes/Loader';
import profileImage from '../../assets/images/profile/profile-img.png';


const Leaderboard = () => {
    const [players, setPlayers] = useState([]);
    const [notFoundLeaderboard, setNotFoundLeaderboard] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useLayoutEffect(() => {
        document.title = 'LeaderBoard | Jazz Esl';
    }, [])

    useEffect(() => {
        async function fetchLeaderboardDetails() {
            try {
                const leaderboard = await getLeaderboard();

                console.log('Leaderboard API Response:', leaderboard);

                const data = Array.isArray(leaderboard.data.data) ? leaderboard.data.data : Object.values(leaderboard.data.data);
                if (data.length > 0) {
                    setPlayers(data);
                } else {
                    setNotFoundLeaderboard(true);
                }
            } catch (error) {
                setNotFoundLeaderboard(true);
            } finally {
                setLoading(false);
            }
        }

        fetchLeaderboardDetails();
    }, []);


    const getRankIcon = (index) => {
        switch (index) {
            case 0:
                return <img src={firstSvg} alt="First Place" className="rank-icon" />;
            case 1:
                return <img src={secondSvg} alt="Second Place" className="rank-icon" />;
            case 2:
                return <img src={thirdSvg} alt="Third Place" className="rank-icon" />;
            default:
                return <span className="rank-number">{index + 1}</span>;
        }
    };

    return (

        <div className='leaderboard-main'>
            <div className='leaderboard-h1-wrap'>
                <h1 className='leaderboard-h1'>LEADERBOARD</h1>
            </div>
            {isLoading ? (
                <Loader />
            ) : notFoundLeaderboard ? <h1 className='not-loaded'>Data could not be loaded please try later</h1> : (
                <ul className='leaderboard-list'>
                    {players.map((player, index) => (
                        <li key={index} className='leaderboard-item'>

                            <div className='rank'>{getRankIcon(index)}</div>
                            <div className='rank-info'>
                                <div className='rank-info-upper-row'>
                                    <div className="user-profile-img">
                                        <img
                                            src={player.profileImage ? player.profileImage : profileImage}
                                            alt="profile img"
                                            className="user-img"
                                            onError={(e) => e.target.src = 'path/to/default-profile-image.jpg'}
                                        />
                                    </div>

                                    <div className="user-profile-info">
                                        <h3 className="user-name">{player.name}</h3>
                                        <p className="user-id">{player.name}</p>
                                    </div>
                                </div>
                                <div className="rank-info-lower-row">
                                    <div className="player-stats">
                                        <h3 className="plater-stats-numvers">{player.score}</h3>
                                        <span className="player-stats-name">Credits</span>
                                    </div>
                                    <div className="player-stats">
                                        <h3 className="plater-stats-numvers">{player.total_tournaments}</h3>
                                        <span className="player-stats-name">Tournaments Played</span>
                                    </div>
                                    <div className="player-stats">
                                        <h3 className="plater-stats-numvers">{player.total_challenges}</h3>
                                        {/* <h3 className="plater-stats-numvers">0</h3> */}
                                        <span className="player-stats-name">Completed Chaalenges</span>
                                    </div>
                                    <div className="player-stats">
                                        <h3 className="plater-stats-numvers">{player.redeemed_items}</h3>
                                        <span className="player-stats-name">Price Redeemed</span>
                                    </div>
                                </div>

                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Leaderboard;
