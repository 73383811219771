import React, {useEffect, useRef, useState} from 'react';
import {requestPostHTTP} from "../helpers/helper";
import {useParams} from "react-router-dom";
import Loader from "../includes/Loader";
import rewardsImg from '../../assets/images/icons/rewards.png';


function EntryDetails() {
    const {game_id} = useParams();
    const {challenge_id} = useParams();
    const [message, setmessage] = useState(null);
    const [notFoundSubmitEntry, setNotSubmitEntry] = useState(false);
    const [loading, setLoading] = useState(true);
    const shoModal = useRef(null);


    useEffect(() => {
        async function fetchReqData() {
            try {
                const getSubmitEntry = await requestPostHTTP('/v1/challenge/entry/details', {
                    gameId: game_id, challengeId: challenge_id
                });

                if (getSubmitEntry.data.eStatus === 'pending') {
                    setmessage('Your challenge entry was submitted successfully.\nThe A.I system will check if your entry is legitimate.\nYou will be awarded credits if entry is legitimate else it will be discarded.\nPlease check back later.');
                    shoModal.current.click();
                } else if (getSubmitEntry.data.eStatus === true) {
                    setmessage('Congratulations! Your entry is successfully approved and you have earned the reward.');
                    shoModal.current.click();
                } else if (getSubmitEntry.data.eStatus === false) {
                    setmessage('Sorry! Your entry is not accepted.');
                } else {
                    setNotSubmitEntry(true);
                }
            } catch (error) {
                console.error('Error fetching game challenges submit entry details data:', error);
                setNotSubmitEntry(true);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();
    }, []);

    return (
        <>
            {/*Model*/}
            <div className="modal fade subscribe-modal" id="successModalLabel" tabindex="-1"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={rewardsImg} alt="entry-success" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                            <p className="subscribe-heading-modal">{message && (message)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{display: 'none'}} ref={shoModal} data-bs-toggle="modal" data-bs-target="#successModalLabel">modal box</button>
            {loading ? (<Loader/>) : (<div className="d-flex justify-content-center align-items-center no-data-found">
                <p>{message && (message)}</p>
            </div>)}
            {notFoundSubmitEntry && (<div className="d-flex justify-content-center align-items-center no-data-found">
                <p>No submit entry details found</p>
            </div>)}

        </>
    );
}

export default EntryDetails;